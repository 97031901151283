import React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import ModalFormComponent from '../modal-form-component';
import { Input, Select } from '../../component/form';
import userAPI from '../../service/userAPI';
import roleAPI from '../../service/roleAPI';

@observer
class UserFormModal extends ModalFormComponent {

    api = userAPI;

    modalNameClassName = 'user-form-modal';

    modalClassName = 'modal--w400';

    modalTitle = this.isCreate ? 'Создание пользователя' : `Редактирование пользователя: ${this.props.item.name}`;

    formConfig = this.prepareConfig({
        id: {
            value: this.props.item.id || null,
        },
        name: {
            label: 'Имя пользователя',
            value: this.props.item.name || '',
        },
        email: {
            label: 'Email',
            value: this.props.item.email || '',
            pattern: 'email',
        },
        password: {
            label: 'Пароль',
            value: this.props.item.password || '',
            required: this.isCreate,
        },
        roleId: {
            label: 'Роль',
            value: this.props.item.roleId || null,
            items: [],
        },
    });

    @action
    async fetchData() {
        const { data } = await roleAPI.fetchAll();
        this.formConfig.roleId.items = data;
    }

    get Body() {
        return (
            <>
                <Input {...this.formConfig.name} />
                <Select {...this.formConfig.roleId} />
                <Input {...this.formConfig.email} />
                <Input {...this.formConfig.password} />
            </>
        );
    }
}

export default UserFormModal;
