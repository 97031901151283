import { NotificationManager } from 'react-notifications';

class Messenger {

    time = 3000;

    success(text) {
        NotificationManager.success(text, 'Success', this.time);
    }

    warning(text) {
        NotificationManager.warning(text, 'Warning', this.time);
    }

    error(text) {
        NotificationManager.error(text, 'Error', this.time);
    }
}

export default new Messenger();
