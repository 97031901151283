import api from './api';

export default new class {

    async upload(file) {
        const data = new FormData();
        data.append('file', file);
        const res = await api.axios.post(`/file`, data);
        return res.data.url;
    }
}();
