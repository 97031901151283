import React from 'react';
import { observer } from 'mobx-react';
import ModalFormComponent from '../modal-form-component';
import { FileUploader, Input, Wysiwyg } from '../../component/form';
import FormLangSwitch from '../../component/form-lang-switch/form-lang-switch';
import offerAPI from '../../service/offerAPI';

@observer
class OfferFormModal extends ModalFormComponent {

    api = offerAPI;

    modalNameClassName = 'offer-form-modal';

    modalClassName = 'modal--w800';

    modalTitle = this.isCreate ? 'Создание предложения' : `Редактирование предложения: ${this.props.item.title_ru}`;

    formConfig = this.prepareConfig({
        id: {
            value: this.props.item.id || null,
        },
        date: {
            label: 'Дата',
            value: (this.props.item.date || '').replace(/T.+/, ''),
            type: 'date',
            required: false,
        },
        title_ru: {
            label: 'Название (ru)',
            value: this.props.item.title_ru || '',
        },
        title_uk: {
            label: 'Название (uk)',
            value: this.props.item.title_uk || '',
        },
        title_en: {
            label: 'Название (en)',
            value: this.props.item.title_en || '',
        },
        image: {
            label: 'Изображение',
            value: this.props.item.image || '',
            required: false,
        },
        imageBig: {
            label: 'Большая картинка',
            value: this.props.item.imageBig || '',
            required: false,
        },
        preContent_ru: {
            label: 'Поле для карточки (ru)',
            value: this.props.item.preContent_ru || '',
            required: false,
        },
        preContent_uk: {
            label: 'Поле для карточки (uk)',
            value: this.props.item.preContent_uk || '',
            required: false,
        },
        preContent_en: {
            label: 'Поле для карточки (en)',
            value: this.props.item.preContent_en || '',
            required: false,
        },
        content_ru: {
            label: 'Контент (ru)',
            value: this.props.item.content_ru || '',
            required: false,
        },
        content_uk: {
            label: 'Контент (uk)',
            value: this.props.item.content_uk || '',
            required: false,
        },
        content_en: {
            label: 'Контент (en)',
            value: this.props.item.content_en || '',
            required: false,
        },
    });

    get Body() {
        return (
            <>
                <FormLangSwitch
                    ru={(
                        <>
                            <Input {...this.formConfig.title_ru} />
                            <Wysiwyg {...this.formConfig.preContent_ru} />
                            <Wysiwyg {...this.formConfig.content_ru} />
                        </>
                    )}
                    uk={(
                        <>
                            <Input {...this.formConfig.title_uk} />
                            <Wysiwyg {...this.formConfig.preContent_uk} />
                            <Wysiwyg {...this.formConfig.content_uk} />
                        </>
                    )}
                    en={(
                        <>
                            <Input {...this.formConfig.title_en} />
                            <Wysiwyg {...this.formConfig.preContent_en} />
                            <Wysiwyg {...this.formConfig.content_en} />
                        </>
                    )}
                />
                <Input {...this.formConfig.date} />
                <FileUploader {...this.formConfig.image} />
                <FileUploader {...this.formConfig.imageBig} />
            </>
        );
    }
}

export default OfferFormModal;
