export const validateTextInput = ({
    value = '',
    required = false,
    type = 'text',
    max = null,
    min = null,
    pattern,
}) => {
    value = value && value.trim ? value.trim() : value;

    if (required && !value)
        return `This field is required`;

    if (type === 'text' && max && value.length > max)
        return `This field must be ${max} character or less`;

    if (type === 'text' && min && value.length < min)
        return `This field must be ${min} character or more`;

    if (type === 'number' && +value > 4_000_000_000)
        return `This field must be less then 4 000 000 000`;

    if (!value)
        return null;

    switch (pattern) {
        case 'text':
            if (!/^[\w!"“”#$%&'()*+,./:;<=>?@[\]^`{|}~\\\s-]+$/.test(value))
                return 'Field contain not valid symbol';
            break;

        case 'email':
            if (!/^[\w\d.]+@[a-zA-Z_0-9.]+?\.[a-zA-Z]{2,10}$/.test(value))
                return 'Field must contain valid e-mail address';
            break;

        case 'letters&numbers':
            if (!/^[\w\d]+$/.test(value))
                return 'Field must contain only letters and numbers';
            break;

        case 'letters':
            if (value && !/^[a-zA-Z_\s]+$/.test(value))
                return 'Field must contain only letters';
            break;

        case 'numbers':
            if (!/^[\d]+$/.test(value))
                return 'Field must contain only numbers';
            break;

        case 'phone':
            if (!/^\+?[\d()\s-]+$/.test(value))
                return 'Field must contain only numbers, space and ()-';
            break;

        case 'url':
            if (!/^https?:\/\/(www\.)?[-\w\d@:%.+~#=]{2,256}\.[a-z]{2,10}\b([-\w@:%+.~#?&/=()]*)$/.test(value))
                return 'Field must contain valid url';
            break;

        default:
            break;
    }

    return null;
};
