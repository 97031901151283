import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Form extends Component {

    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.any,
        onSubmit: PropTypes.func.isRequired,
    }

    render() {
        return (
            <form
                className={this.props.className}
                onSubmit={this.props.onSubmit}
                autoComplete="off"
                noValidate
                onClick={e => e.stopPropagation()}
            >
                {this.props.children}
            </form>
        );
    }
}

export default Form;
