import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { createBrowserHistory } from 'history';
import { Router, Switch } from 'react-router';
import { NotificationContainer } from 'react-notifications';
import store from './store'
import './scss/main.scss';
import PublicRoute from './component/route/public-route';
import PrivateRoute from './component/route/private-route';
import LoginPage from './page/login-page/login-page';
import StartPage from './page/start-page/start-page';
import NewsTablePage from './page/news-table-page/news-table-page';
import RoomTablePage from './page/room-table-page/room-table-page';
import DishCategoryTablePage from './page/dish-category-table-page/dish-category-table-page';
import DishTablePage from './page/dish-table-page/dish-table-page';
import GalleryTablePage from './page/gallery-table-page/gallery-table-page';
import PageTablePage from './page/page-table-page/page-table-page';
import UserTablePage from './page/user-table-page/user-table-page';
import RoleTablePage from './page/role-table-page/role-table-page';
import TranslationTablePage from './page/translation-table-page/translation-table-page';
import SettingTablePage from './page/setting-table-page/setting-table-page';
import OrderTablePage from './page/order-table-page/order-table-page';
import OfferTablePage from './page/offer-table-page/offer-table-page';

const history = createBrowserHistory();

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <Switch>
                <PublicRoute path="/" exact component={LoginPage} />
                <PrivateRoute path="/start" component={StartPage} />
                <PrivateRoute path="/orders" component={OrderTablePage} />
                <PrivateRoute path="/news" component={NewsTablePage} />
                <PrivateRoute path="/offers" component={OfferTablePage} />
                <PrivateRoute path="/rooms" component={RoomTablePage} />
                <PrivateRoute path="/dish-categories" component={DishCategoryTablePage} />
                <PrivateRoute path="/dishes" component={DishTablePage} />
                <PrivateRoute path="/gallery" component={GalleryTablePage} />
                <PrivateRoute path="/pages" component={PageTablePage} />
                <PrivateRoute path="/users" component={UserTablePage} />
                <PrivateRoute path="/roles" component={RoleTablePage} />
                <PrivateRoute path="/translations" component={TranslationTablePage} />
                <PrivateRoute path="/settings" component={SettingTablePage} />
            </Switch>
            <NotificationContainer />
        </Router>
    </Provider>,
    document.getElementById('root')
);
