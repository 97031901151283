import React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import ModalFormComponent from '../modal-form-component';
import { Input, Checkbox } from '../../component/form';
import permission from '../../service/permission';
import roleAPI from '../../service/roleAPI';
import './role-form-modal.scss';

@observer
class RoleFormModal extends ModalFormComponent {

    api = roleAPI;

    modalNameClassName = 'role-form-modal';

    modalTitle = this.isCreate ? 'Создание роли' : `Редактирование роли: ${this.props.item.title}`;

    formConfig = this.prepareConfig({
        id: {
            value: this.props.item.id || null,
        },
        title: {
            label: 'Title',
            value: this.props.item.title || '',
        },
        permissions: {
            name: 'permissions',
            value: new Set(this.props.item.permissions || []),
        }
    });

    get Body() {
        return (
            <>
                <Input {...this.formConfig.title} />
                <div className="role-form-modal__permission-title">Разрешения</div>
                {
                    Object.values(permission.list).map(x => (
                        <Checkbox
                            key={x}
                            label={x}
                            value={x}
                            checked={this.formConfig.permissions.value.has(x)}
                            onChange={this.onPermissionChange}
                        />
                    ))
                }
            </>
        );
    }

    @action
    onPermissionChange = ({ value, checked }) => {
        this.formConfig.permissions.value[checked ? 'add' : 'delete'](value);
    };
}

export default RoleFormModal;
