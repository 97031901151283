import { observable, action } from 'mobx';

const LS = window.localStorage;

class Store {

    @observable
    user = Store.getUserFromLS();

    @observable
    lang = LS.getItem('lang') || 'en';

    static getUserFromLS() {
        const json = LS.getItem('user');
        return json ? JSON.parse(json) : null;
    }

    get token() {
        return this.user?.token ?? null;
    }

    @action
    setUser(user) {
        this.user = user;
        LS.setItem('user', JSON.stringify(this.user));
    }

    @action
    resetUser() {
        this.user = null;
        LS.removeItem('user');
    }

    @action
    setLang(lang) {
        this.lang = lang;
        LS.setItem('lang', lang);
    }
}

export default new Store();
