import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from '../../icon/icon';
import fileAPI from '../../../service/fileAPI';
import './file-uploader.scss';

class FileUploader extends Component {

    static propTypes = {
        className: PropTypes.string,
        label: PropTypes.string,
        name: PropTypes.string.isRequired,
        value: PropTypes.string,
        error: PropTypes.string,
        readOnly: PropTypes.bool,
        disabled: PropTypes.bool,
        required: PropTypes.bool,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        className: '',
        label: '',
        value: null,
        error: null,
        readOnly: false,
        disabled: false,
        required: false,
        onChange: () => {},
    };

    get formFieldClassname() {
        return cn(
            'form-field',
            'file-uploader',
            this.props.className,
        );
    }

    get Uploader() {
        return (
            <label className="file-uploader__uploader">
                <input
                    type="file"
                    name={this.props.name}
                    value={this.props.value || ''}
                    readOnly={this.props.readOnly}
                    disabled={this.props.disabled}
                    onChange={({ target }) => this.onFileChange(target.files[0])}
                />
                <Icon className="file-uploader__icon" icon="backup" />
                <div className="file-uploader__text">Click to upload</div>
            </label>
        );
    }

    get Preview() {
        return (
            <div className="file-uploader__preview">
                <div
                    className="file-uploader__preview-image"
                    style={{ backgroundImage: `url("${this.props.value}")` }}
                />
                {
                    !this.props.readOnly && !this.props.disabled && (
                        <Icon
                            className="file-uploader__preview-delete"
                            icon="close"
                            onClick={() => this.onFileDelete()}
                        />
                    )
                }
                <input className="file-uploader__preview-input" readOnly value={this.props.value} />
            </div>
        );
    }

    async onFileChange(file) {
        const value = await fileAPI.upload(file);
        this.props.onChange({ name: this.props.name, value });
    }

    async onFileDelete() {
        if (this.props.readOnly || this.props.disabled) return;
        this.props.onChange({ name: this.props.name, value: null, deletedValue: this.props.value });
    }

    render() {
        return (
            <div className={this.formFieldClassname}>
                <label className="form-field__label">{this.props.label}</label>
                <div className="file-uploader__box">
                    {this.props.value ? this.Preview : this.Uploader}
                </div>
                {this.props.error && <div className="form-field__error">{this.props.error}</div>}
            </div>
        );
    }
}

export default FileUploader;
