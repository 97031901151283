import axios from 'axios';
import messenger from './messenger';
import store from '../store'

const API_HOST = process.env.REACT_APP_API_HOST || 'http://localhost:4111';
if (!API_HOST) throw Error('API_HOST is undefined');

class Api {

    constructor(axios) {
        this.axios = axios.create();
        this.axios.defaults.baseURL = API_HOST;
        this.axios.interceptors.response.use(
            this.onFulfilledInterceptor,
            this.onRejectedInterceptor
        );
        if (store.token) this.addAuthHeader(store.token);
    }

    onFulfilledInterceptor = res => res;

    onRejectedInterceptor = err => {
        const res = err.response;
        if (!res) this.logout();
        if (res?.data?.message) messenger.error(res.data.message);

        console.error(err.toJSON());

        return Promise.reject(err);
    }

    addAuthHeader(token) {
        this.axios.defaults.headers['common']['Authorization'] = `Bearer ${token}`;
    }

    removeAuthHeader() {
        delete this.axios.defaults.headers['common']['Authorization'];
    }

    async login(data) {
        const res = await this.axios.post(`/login`, data);
        this.addAuthHeader(res.data.token);
        store.setUser(res.data);
    }

    logout() {
        this.removeAuthHeader();
        store.resetUser();
    }

    fetchCoreData() {
        return Promise.resolve();
    }

    log(msg) {
        console.log(msg);
    }
}

export default new Api(axios);
