import api from './api';
import messenger from './messenger';

export default class AbstractAPI {

    route = '/entity';

    async fetch(params) {
        return api.axios.get(this.route, { params });
    }

    async fetchAll() {
        return api.axios.get(`${this.route}/all`);
    }

    async save(data) {
        const res = data.id
            ? await api.axios.put(`${this.route}/${data.id}`, data)
            : await api.axios.post(this.route, data);

        messenger.success('Данные успешно сохранены');
        return res;
    }

    async delete(id) {
        const res = await api.axios.delete(`${this.route}/${id}`);
        messenger.success('Данные успешно удалены');
        return res;
    }
}
