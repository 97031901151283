import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import JoditEditor from 'jodit-react';
import './wysiwyg.scss';

const Wysiwyg = ({
    className,
    label,
    name,
    value,
    error,
    required,
    disabled,
    onChange,
}) => {
    const buttons = [
        'source',
        'paragraph',
        'bold',
        'strikethrough',
        'underline',
        'italic',
        'eraser',
        'ul',
        'outdent',
        'indent',
        'image',
        'table',
        'link',
        'align',
        'undo',
        'redo',
        'hr',
        'fullsize',
    ];

    const config = {
        disabled,
        buttons,
        buttonsXS: buttons,
        buttonsSM: buttons,
        buttonsMD: buttons,
        minHeight: 100,
        limitChars: 10000,
        defaultActionOnPaste: 'insert_only_html',
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        toolbarSticky: false,
        uploader: { insertImageAsBase64URI: true },
    };

    const formFieldClassname = cn(
        'form-field',
        'wysiwyg',
        className,
        {
            'form-field--invalid': error
        }
    );

    return (
        <div className={formFieldClassname}>
            {label && <label className="form-field__label">{label} {required ? '*' : ''}</label>}
            <div className="form-field__input-box">
                <JoditEditor
                    value={value}
                    config={config}
                    tabIndex={1}
                    onBlur={({ target }) => onChange({ name, value: target.innerHTML })}
                />
            </div>
            {error && <div className="form-field__error">{error}</div>}
        </div>
    )
};

Wysiwyg.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    error: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

Wysiwyg.defaultProps = {
    className: '',
    label: '',
    value: '',
    error: null,
    required: true,
    disabled: false,
    onChange: () => {},
};

export default Wysiwyg;
