import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './form-lang-switch.scss';

const FormLangSwitch = props => {
    const languages = ['ru', 'uk', 'en'];
    const [activeLang, setLang] = useState('ru');

    return (
        <div className="form-lang-switch">
            <div className="form-lang-switch__controls">
                {
                    languages.map(x => (
                        <div
                            key={x}
                            className={cn(
                                'form-lang-switch__controls-item',
                                { 'form-lang-switch__controls-item--active': activeLang === x }
                            )}
                            onClick={() => setLang(x)}
                        >{x}</div>
                    ))
                }
            </div>
            <div className="form-lang-switch__body">
                {
                    languages.map(x => (
                        <div
                            key={x}
                            className={cn(
                                'form-lang-switch__item',
                                { 'form-lang-switch__item--active': activeLang === x }
                            )}
                        >
                            {props[x]}
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

FormLangSwitch.propTypes = {
    ru: PropTypes.element.isRequired,
    uk: PropTypes.element.isRequired,
    en: PropTypes.element.isRequired,
}

export default FormLangSwitch;
