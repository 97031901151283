import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { QP_TYPE, getQueryParamsFromCurrentUrl, setQueryParamsToCurrentUrl } from '../../service/queryParams';
import Button from '../button/button';
import Icon from '../icon/icon';
import SortTh from './sort-th';
import FilterInput from './filter-input/filter-input';
import FilterSelect from './filter-select/filter-select';
import Pagination from '../pagination/pagination';
import ConfirmModal from '../../modal/confirm-modal/confirm-modal';
import './table-page.scss';

@observer
class TablePage extends Component {

    api;

    columnsConfig = [
        {
            _key: 'id',
            title: 'ID',
            sortable: true,
            filter: {
                param: 'filter.eq.id',
                type: 'number',
            }
        },
        {
            _key: 'actions',
            filter: {
                type: 'reset'
            }
        }
    ];

    @observable
    loading = true;

    items = [];

    itemsCount = null;

    queryParamsConfig = [
        { key: 'page', type: QP_TYPE.integer, default: 1 },
        { key: 'sort', type: QP_TYPE.string, default: 'id' },
        { key: 'dir', type: QP_TYPE.string, default: 'asc' },
    ];

    @observable
    queryParams = {
        ...this.defaultQueryParams
    };

    @observable
    itemForModal = null;

    @observable
    itemForDelete = null;

    get defaultQueryParams() {
        const params = {};
        this.queryParamsConfig.forEach(x => {
            params[x.key] = x.default;
        });
        return params;
    }

    get isDefaultQueryParams() {
        return this.queryParamsConfig.every(x => this.queryParams[x.key] === x.default);
    }

    get TableHead() {
        const columns = this.columnsConfig
            .map(x => (
                x.sortable === false || x._key === 'actions'
                    ? <th key={x._key}>{x.title}</th>
                    : (
                        <SortTh
                            key={x._key}
                            sortKey={x._key}
                            activeSortKey={this.queryParams.sort}
                            dir={this.queryParams.dir}
                            title={x.title}
                            onChange={this.onSortChange}
                        />
                    )
            ))

        return (
            <thead>
                <tr>{columns}</tr>
            </thead>
        );
    }

    get FiltersRow() {
        const getFilter = config => {
            if (!config.filter) return null;

            const { param, type, items } = config.filter;
            const value = this.queryParams[param];

            switch (type) {
                case 'text':
                case 'number':
                    return (
                        <FilterInput
                            param={param}
                            type={type}
                            placeholder={config.title}
                            value={value}
                            onChange={this.onFilterChange}
                        />
                    );

                case 'select':
                    return (
                        <FilterSelect
                            param={param}
                            value={value}
                            items={items}
                            onChange={this.onFilterChange}
                        />
                    );

                case 'reset':
                    return (
                        <Button
                            text="Сбросить фильтры"
                            styleType="default"
                            sizeType="sm"
                            onClick={this.onResetFilters}
                        />
                    );

                default:
                    return <td />;
            }
        };

        const columns = this.columnsConfig
            .map(x => <td key={x._key}>{getFilter(x)}</td>)

        return <tr className="table-page__filter-row">{columns}</tr>;
    }

    get NoResult() {
        if (this.items.length) return null;

        if (!this.isDefaultQueryParams) return (
            <div className="table-page__no-results">Нет результатов c этим фильтром</div>
        );

        return <div className="table-page__no-results">Нет данных</div>;
    }

    get Pagination() {
        return (
            <Pagination
                page={this.queryParams.page}
                total={this.itemsCount}
                onChange={this.onPageChange}
            />
        );
    }

    get DeleteModal() {
        if (!this.itemForDelete) return null;

        return (
            <ConfirmModal
                onApply={() => this.onDeleteConfirmed(this.itemForDelete)}
                onClose={() => this.setItemForDelete(null)}
            />
        );
    }

    EditButton(x) {
        return (
            <Button
                text={<Icon icon="edit" />}
                title="Редактировать"
                styleType="default"
                className="button-icon"
                onClick={() => this.setItemForModal(x)}
            />
        )
    }

    DeleteButton(x) {
        return (
            <Button
                text={<Icon icon="delete_forever" />}
                title="Удалить"
                styleType="danger"
                className="button-icon"
                onClick={() => this.setItemForDelete(x)}
            />
        )
    }

    componentDidMount() {
        this.initQueryParams();
        this.setQpToUrl();
        this.fetchData();
    }

    @action
    initQueryParams() {
        this.queryParams = {
            ...this.queryParams,
            ...getQueryParamsFromCurrentUrl(this.queryParamsConfig)
        };
    }

    async fetchData() {
        this.setLoading(true);
        const res = await this.api.fetch(this.queryParams);
        this.items = res.data.rows;
        this.itemsCount = res.data.count;
        this.setLoading(false);
    }

    onDeleteConfirmed(item) {
        this.api.delete(item.id).then(() => this.fetchData());
    }

    @action
    setLoading(state) {
        this.loading = state;
    }

    setQpToUrl() {
        setQueryParamsToCurrentUrl(this.queryParamsConfig, this.queryParams);
    }

    @action
    setItemForModal(item) {
        this.itemForModal = item;
    };

    @action
    setItemForDelete(item) {
        this.itemForDelete = item;
    }

    @action
    onSortChange = (sort, dir) => {
        this.queryParams.sort = sort;
        this.queryParams.dir = dir;
        this.setQpToUrl();
        this.fetchData();
    };

    @action
    onFilterChange = (param, value) => {
        this.queryParams.page = 1;
        this.queryParams[param] = value;
        this.setQpToUrl();
        this.fetchData();
    };

    @action
    onPageChange = page => {
        this.queryParams.page = page;
        this.setQpToUrl();
        this.fetchData();
    };

    @action
    onResetFilters = () => {
        this.queryParams = {
            ...this.defaultQueryParams
        };
        this.setQpToUrl();
        this.fetchData();
    };

    render() { return null; }
}

export default TablePage;
