import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import TablePage from '../../component/table/table-page';
import Loader from '../../component/loader/loader';
import OrderFormModal from '../../modal/order-form-modal/order-form-modal';
import { QP_TYPE } from '../../service/queryParams';
import permission from '../../service/permission';
import orderAPI from '../../service/orderAPI';

@observer
class OrderTablePage extends TablePage {

    queryParamsConfig = [
        { key: 'page', type: QP_TYPE.integer, default: 1 },
        { key: 'sort', type: QP_TYPE.string, default: 'id' },
        { key: 'dir', type: QP_TYPE.string, default: 'asc' },
        { key: 'filter.eq.id', type: QP_TYPE.integer, default: null },
        { key: 'filter.eq.sum', type: QP_TYPE.integer, default: null },
        { key: 'filter.eq.status', type: QP_TYPE.integer, default: null },
        { key: 'filter.like.username', type: QP_TYPE.string, default: null },
        { key: 'filter.like.phone', type: QP_TYPE.string, default: null },
    ];

    columnsConfig = [
        {
            _key: 'id',
            title: 'ID',
            filter: {
                param: 'filter.eq.id',
                type: 'number',
            }
        },
        {
            _key: 'date',
            title: 'Дата',
        },
        {
            _key: 'sum',
            title: 'Сумма',
            filter: {
                param: 'filter.eq.sum',
                type: 'number',
            }
        },
        {
            _key: 'status',
            title: 'Статус',
            filter: {
                param: 'filter.eq.status',
                type: 'select',
                items: [
                    'new',
                    'in_progress',
                    'completed',
                    'canceled',
                ].map(v => ({ v }))
            }
        },
        {
            _key: 'username',
            title: 'Имя клиента',
            filter: {
                param: 'filter.like.username',
                type: 'text',
            }
        },
        {
            _key: 'phone',
            title: 'Телефон',
            filter: {
                param: 'filter.like.phone',
                type: 'text',
            }
        },
        {
            _key: 'actions',
            filter: {
                type: 'reset'
            }
        }
    ];

    api = orderAPI;

    render() {
        if (this.loading && this.itemsCount === null) return <Loader />;

        return (
            <div className="table-page">
                <table>
                    {this.TableHead}
                    <tbody>
                        {this.FiltersRow}
                        {
                            this.items.map(x => (
                                <tr key={x.id}>
                                    <td>{x.id}</td>
                                    <td>{moment(x.createdAt).format('DD.MM.YYYY HH:mm')}</td>
                                    <td>{x.sum}</td>
                                    <td>{x.status}</td>
                                    <td>{x.username}</td>
                                    <td>{x.phone}</td>
                                    {
                                        permission.can(permission.list.ORDER_MANAGE) && (
                                            <td className="actions">
                                                <div className="button-box">
                                                    {this.EditButton(x)}
                                                </div>
                                            </td>
                                        )
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                {this.NoResult}
                {this.Pagination}
                {
                    this.itemForModal &&
                    <OrderFormModal
                        item={this.itemForModal}
                        onClose={() => this.setItemForModal(null)}
                        onSubmitSuccess={() => this.fetchData()}
                    />
                }
            </div>
        )
    }
}

export default OrderTablePage;
