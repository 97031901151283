import React from 'react';
import { observer } from 'mobx-react';
import ModalFormComponent from '../modal-form-component';
import { Input } from '../../component/form';
import FormLangSwitch from '../../component/form-lang-switch/form-lang-switch';
import ImageArrayUploader from '../../component/image-array-uploader/image-array-uploader';
import roomAPI from '../../service/roomAPI';
import './room-form-modal.scss';

@observer
class RoomFormModal extends ModalFormComponent {

    api = roomAPI;

    modalNameClassName = 'room-form-modal';

    modalClassName = 'modal--w1000';

    modalBodyClassName = 'modal__body--2-cols';

    modalTitle = `Редактирование комнаты: ${this.props.item.title}`;

    formConfig = this.prepareConfig({
        id: {
            value: this.props.item.id,
        },
        title: {
            label: 'Название',
            value: this.props.item.title || '',
        },
        price: {
            label: 'Цена',
            value: this.props.item.price || 0,
            type: 'number',
        },
        roomCount: {
            label: 'Количество комнат',
            value: this.props.item.roomCount || 1,
            type: 'number',
        },
        area: {
            label: 'Площадь',
            value: this.props.item.area || 1,
            type: 'number',
        },
        bed: {
            label: 'Кровать',
            value: this.props.item.bed || '',
        },
        tv: {
            label: 'TV',
            value: this.props.item.tv || '',
        },
        bookingId: {
            label: 'bookingId',
            value: this.props.item.bookingId || '',
        },
        images: {
            value: this.props.item.images || [],
        },
        seo_title_ru: {
            label: 'SEO заголовок (ru)',
            value: this.props.item.seo_title_ru || '',
            required: false,
        },
        seo_title_uk: {
            label: 'SEO заголовок (uk)',
            value: this.props.item.seo_title_uk || '',
            required: false,
        },
        seo_title_en: {
            label: 'SEO заголовок (en)',
            value: this.props.item.seo_title_en || '',
            required: false,
        },
        seo_description_ru: {
            label: 'SEO описание (ru)',
            value: this.props.item.seo_description_ru || '',
            required: false,
        },
        seo_description_uk: {
            label: 'SEO описание (uk)',
            value: this.props.item.seo_description_uk || '',
            required: false,
        },
        seo_description_en: {
            label: 'SEO описание (en)',
            value: this.props.item.seo_description_en || '',
            required: false,
        },
    });

    get Body() {
        return (
            <>
                <div>
                    <Input {...this.formConfig.title} />
                    <Input {...this.formConfig.price} />
                    <Input {...this.formConfig.roomCount} />
                    <Input {...this.formConfig.area} />
                    <Input {...this.formConfig.bed} />
                    <Input {...this.formConfig.tv} />
                    <Input {...this.formConfig.bookingId} />
                    <FormLangSwitch
                        ru={(
                            <>
                                <Input {...this.formConfig.seo_title_ru} />
                                <Input {...this.formConfig.seo_description_ru} />
                            </>
                        )}
                        uk={(
                            <>
                                <Input {...this.formConfig.seo_title_uk} />
                                <Input {...this.formConfig.seo_description_uk} />
                            </>
                        )}
                        en={(
                            <>
                                <Input {...this.formConfig.seo_title_en} />
                                <Input {...this.formConfig.seo_description_en} />
                            </>
                        )}
                    />
                </div>
                <div>
                    <ImageArrayUploader {...this.formConfig.images} />
                </div>
            </>
        );
    }
}

export default RoomFormModal;
