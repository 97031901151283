import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './button.scss';

const Button = ({
    className,
    submit,
    styleType,
    sizeType,
    disabled,
    text,
    title,
    onClick,
}) => (
    <button
        type={submit ? 'submit' : 'button'}
        className={cn(
            'button',
            `button-${styleType}`,
            sizeType ? `button--${sizeType}` : null,
            className
        )}
        disabled={disabled}
        title={title}
        onClick={onClick}
    >
        {text}
    </button>
);

Button.propTypes = {
    className: PropTypes.string,
    submit: PropTypes.bool,
    styleType: PropTypes.oneOf([
        'default',
        'primary',
        'success',
        'info',
        'warning',
        'danger',
    ]),
    sizeType: PropTypes.oneOf(['sm', 'lg']),
    disabled: PropTypes.bool,
    text: PropTypes.node.isRequired,
    title: PropTypes.string,
    onClick: PropTypes.func,
};

Button.defaultProps = {
    className: null,
    submit: false,
    styleType: 'default',
    disabled: false,
    title: null,
};

export default Button;
