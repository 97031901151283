import React from 'react';
import { observer } from 'mobx-react';
import ModalFormComponent from '../modal-form-component';
import { Input } from '../../component/form';
import settingAPI from '../../service/settingAPI';

@observer
class SettingFormModal extends ModalFormComponent {

    api = settingAPI;

    modalNameClassName = 'setting-form-modal';

    modalClassName = 'modal--w400';

    modalTitle = `Редактирование: ${this.props.item.key}`;

    formConfig = this.prepareConfig({
        id: {
            value: this.props.item.id,
        },
        key: {
            label: 'Ключ',
            value: this.props.item.key || '',
            disabled: true,
        },
        value: {
            label: 'Значение',
            value: this.props.item.value || '',
        },
    });

    get Body() {
        return (
            <>
                <Input {...this.formConfig.key} />
                <Input {...this.formConfig.value} />
            </>
        );
    }
}

export default SettingFormModal;
