import React from 'react';
import { observer } from 'mobx-react';
import ModalFormComponent from '../modal-form-component';
import { Input } from '../../component/form';
import ImageArrayUploader from '../../component/image-array-uploader/image-array-uploader';
import galleryAPI from '../../service/galleryAPI';
import './gallery-form-modal.scss';

@observer
class GalleryFormModal extends ModalFormComponent {

    api = galleryAPI;

    modalNameClassName = 'gallery-form-modal';

    modalClassName = 'modal--w1000';

    modalBodyClassName = 'modal__body--2-cols';

    modalTitle = `Редактирование галереи: ${this.props.item.title_ru}`;

    formConfig = this.prepareConfig({
        id: {
            value: this.props.item.id,
        },
        title_ru: {
            label: 'Название (ru)',
            value: this.props.item.title_ru || '',
        },
        title_uk: {
            label: 'Название (uk)',
            value: this.props.item.title_uk || '',
        },
        title_en: {
            label: 'Название (en)',
            value: this.props.item.title_en || '',
        },
        images: {
            value: this.props.item.images || [],
        },
    });

    get Body() {
        return (
            <>
                <div>
                    <Input {...this.formConfig.title_ru} />
                    <Input {...this.formConfig.title_uk} />
                    <Input {...this.formConfig.title_en} />
                </div>
                <div>
                    <ImageArrayUploader {...this.formConfig.images} />
                </div>
            </>
        );
    }
}

export default GalleryFormModal;
