import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import cn from 'classnames';
import FormComponent from '../component/form/form-component';
import Form from '../component/form/form';
import Button from '../component/button/button';
import React from 'react';

@observer
class ModalFormComponent extends FormComponent {

    api;

    modalNameClassName = null;

    modalClassName = null;

    modalBodyClassName = null;

    isCreate = this.props.item && !this.props.item.id;

    onClose = this.props.onClose || (() => {});

    onSubmitSuccess = this.props.onSubmitSuccess || (() => {});

    @observable
    show = false;

    get modalBoxClasses() {
        return cn('modal-holder', { 'show': this.show });
    }

    async componentDidMount() {
        await this.fetchData();
        setTimeout(action(() => this.show = true), 200);
        document.body.addEventListener('keydown', this.closeByEscape);
    }

    componentWillUnmount() {
        document.body.removeEventListener('keydown', this.closeByEscape);
    }

    get Body() {
        return null;
    }

    @action
    async fetchData() {}

    closeByEscape = e => e.key === 'Escape' && this.onClose();

    async submit(formData) {
        try {
            await this.api.save(formData)
            this.onSubmitSuccess();
            this.onClose();
        } catch (e) {
            this.setErrorsFromResponse(e.response);
        }
    }

    render() {
        return (
            <div className={this.modalBoxClasses}>
                <Form
                    className={cn('modal', this.modalNameClassName, this.modalClassName)}
                    onSubmit={this.onSubmit}
                >
                    <div className="modal__header">
                        <div className="modal__title">{this.modalTitle}</div>
                    </div>
                    <div className={cn('modal__body', this.modalBodyClassName)}>
                        {this.Body}
                    </div>
                    <div className="modal__footer">
                        <Button text="Отмена" styleType="default" onClick={() => this.onClose()} />
                        <Button text="Сохранить" styleType="primary" submit />
                    </div>
                </Form>
            </div>
        );
    }
}

export default ModalFormComponent;
