import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Icon = ({ className, icon, onClick }) => (
    <i
        className={cn('material-icons', className)}
        onClick={onClick}
    >{icon}</i>
);

Icon.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

Icon.defaultProps = {
    className: null,
    onClick: () => {},
};

export default Icon;
