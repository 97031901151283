import React, { Component } from 'react';
import './start-page.scss';

class StartPage extends Component {
    render() {
        return (
            <div className="start-page">
                <h2>Добро пожаловать в Админ панель!</h2>
                <div className="start-page__info-block">
                    Для подписки на уведомления в Telegram,
                    необходимо найти в приложении бота с именем <b> Kiroff_hotel_admin </b>
                    после чего начать с ним чат кнопкой "Start" или текстом "/start".
                    номер чата который прийдет в ответ нужно указать на странице
                    <a href="/settings?page=1&sort=id&dir=asc&filter.like.key=Telegram_bot_chat_ids"> настроек </a>
                    в настройке с ключем "Telegram_bot_chat_ids".
                    Идентификаторы чатов указываються через запятую.
                </div>
            </div>
        );
    }
}

export default StartPage;
