import { Component } from 'react';
import { observable, action, ObservableSet } from 'mobx';
import { observer } from 'mobx-react';
import { validateTextInput } from '../../service/validation';

@observer
class FormComponent extends Component {

    @observable
    loading = false;

    @observable
    formConfig = {};

    prepareConfig(config) {
        for (const name in config) {
            if (name === 'id') continue;
            config[name].name = name;
            config[name].error = null;
            config[name].required = config[name].required ?? true;
            config[name].onChange = this.setInputValue;
            config[name].onBlur = this.validateInput;
        }
        return config;
    }

    get formData() {
        const data = {};
        for (const name in this.formConfig) {
            const { value } = this.formConfig[name];
            if (
                value instanceof Set ||
                value instanceof ObservableSet
            ) data[name] = [...value];
            else data[name] = value;
        }
        return data;
    }

    get isFormValid() {
        for (const name in this.formConfig) if (this.formConfig[name].error) return false;
        return true;
    }

    @action
    setLoading(state) {
        this.loading = state;
    }

    @action
    setInputValue = ({ name, value }) => {
        this.formConfig[name].value = value;
    };

    @action
    validateInput = name => {
        this.formConfig[name].error = validateTextInput(this.formConfig[name]);
    };

    validateForm() {
        for (const name in this.formConfig) this.validateInput(name);
    }

    setErrorsFromResponse(response) {
        if (response?.data?.errors) this.setErrors(response.data.errors);
    }

    @action
    setErrors(errors) {
        for (const name in this.formConfig) {
            const error = errors.find(x => x.path === name);
            if (error) this.formConfig[name].error = error.message;
        }
    }

    @action
    onSubmit = e => {
        e.preventDefault();
        this.validateForm();
        if (!this.isFormValid) return;
        this.submit(this.formData);
    };

    submit() {}

    render() { return null; }
}

export default FormComponent;
