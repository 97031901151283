import React from 'react';
import { observer } from 'mobx-react';
import TablePage from '../../component/table/table-page';
import Loader from '../../component/loader/loader';
import RoomFormModal from '../../modal/room-form-modal/room-form-modal';
import { QP_TYPE } from '../../service/queryParams';
import permission from '../../service/permission';
import roomAPI from '../../service/roomAPI';

@observer
class RoomTablePage extends TablePage {

    queryParamsConfig = [
        { key: 'page', type: QP_TYPE.integer, default: 1 },
        { key: 'sort', type: QP_TYPE.string, default: 'id' },
        { key: 'dir', type: QP_TYPE.string, default: 'asc' },
        { key: 'filter.eq.id', type: QP_TYPE.integer, default: null },
        { key: 'filter.like.key', type: QP_TYPE.string, default: null },
        { key: 'filter.like.title', type: QP_TYPE.string, default: null },
        { key: 'filter.eq.price', type: QP_TYPE.string, default: null },
    ];

    columnsConfig = [
        {
            _key: 'id',
            title: 'ID',
            filter: {
                param: 'filter.eq.id',
                type: 'number',
            }
        },
        {
            _key: 'key',
            title: 'Ключ',
            filter: {
                param: 'filter.like.key',
                type: 'text',
            }
        },
        {
            _key: 'title',
            title: 'Название',
            filter: {
                param: 'filter.like.title',
                type: 'text',
            }
        },
        {
            _key: 'price',
            title: 'Цена',
            filter: {
                param: 'filter.eq.price',
                type: 'number',
            }
        },
        {
            _key: 'actions',
            filter: {
                type: 'reset'
            }
        }
    ];

    api = roomAPI;

    render() {
        if (this.loading && this.itemsCount === null) return <Loader />;

        return (
            <div className="table-page">
                <table>
                    {this.TableHead}
                    <tbody>
                        {this.FiltersRow}
                        {
                            this.items.map(x => (
                                <tr key={x.id}>
                                    <td>{x.id}</td>
                                    <td>{x.key}</td>
                                    <td>{x.title}</td>
                                    <td>{x.price}</td>
                                    <td className="actions">
                                        {
                                            permission.can(permission.list.ROOM_MANAGE) && (
                                                <div className="button-box">
                                                    {this.EditButton(x)}
                                                </div>
                                            )
                                        }
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                {this.NoResult}
                {this.Pagination}
                {
                    this.itemForModal &&
                    <RoomFormModal
                        item={this.itemForModal}
                        onClose={() => this.setItemForModal(null)}
                        onSubmitSuccess={() => this.fetchData()}
                    />
                }
            </div>
        )
    }
}

export default RoomTablePage;
