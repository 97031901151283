import React from 'react';
import { observer } from 'mobx-react';
import FormComponent from '../../component/form/form-component';
import Button from '../../component/button/button';
import { Form, Input } from '../../component/form';
import api from '../../service/api';
import './login-page.scss';

@observer
class LoginPage extends FormComponent {

    formConfig = this.prepareConfig({
        email: {
            label: 'Email',
            value: '',
            pattern: 'email',
        },
        password: {
            label: 'Password',
            value: '',
            type: 'password',
        }
    });

    render() {
        return (
            <div className="login-page">
                <Form className="login-page__form" onSubmit={this.onSubmit}>
                    <div className="login-page__title">Kiroff-hotel Admin</div>
                    <Input {...this.formConfig.email} />
                    <Input {...this.formConfig.password} />
                    <Button text="Login" styleType="primary" disabled={this.loading} submit />
                </Form>
            </div>
        );
    }

    submit(formData) {
        this.setLoading(true);
        api.login(formData)
            .then(() => api.log('login successful'))
            .finally(() => this.setLoading(false));
    }
}

export default LoginPage;
