import React from 'react';
import { observer } from 'mobx-react';
import ModalFormComponent from '../modal-form-component';
import Form from '../../component/form/form';
import Button from '../../component/button/button';
import './confirm-modal.scss';

@observer
class ConfirmModal extends ModalFormComponent {

    onApply = this.props.onApply || (() => {});

    render() {
        const modalTitle = 'Вы уверены?';

        return (
            <div className={this.modalBoxClasses}>
                <Form className="modal confirm-modal" onSubmit={this.onSubmit}>
                    <div className="modal__header">
                        <h3>{modalTitle}</h3>
                    </div>
                    <div className="modal__footer">
                        <Button
                            text="Нет"
                            styleType="default"
                            onClick={() => this.onClose()}
                        />
                        <Button
                            text="Да"
                            styleType="danger"
                            submit
                        />
                    </div>
                </Form>
            </div>
        );
    }

    submit() {
        this.onApply();
        this.onClose();
    }
}

export default ConfirmModal;
