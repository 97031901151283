import React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import ModalFormComponent from '../modal-form-component';
import { Input, Select, Wysiwyg } from '../../component/form';
import FormLangSwitch from '../../component/form-lang-switch/form-lang-switch';
import ImageArrayUploader from '../../component/image-array-uploader/image-array-uploader';
import dishCategoryAPI from '../../service/dishCategoryAPI';
import dishAPI from '../../service/dishAPI';

@observer
class DishFormModal extends ModalFormComponent {

    api = dishAPI;

    modalNameClassName = 'dish-form-modal';

    modalClassName = 'modal--w1000';

    modalBodyClassName = 'modal__body--2-cols';

    modalTitle = this.isCreate ? 'Создание блюда' : `Редактирование блюда: ${this.props.item.title_ru}`;

    formConfig = this.prepareConfig({
        id: {
            value: this.props.item.id || null,
        },
        title_ru: {
            label: 'Название (ru)',
            value: this.props.item.title_ru || '',
        },
        title_uk: {
            label: 'Название (uk)',
            value: this.props.item.title_uk || '',
        },
        title_en: {
            label: 'Название (en)',
            value: this.props.item.title_en || '',
        },
        description_ru: {
            label: 'Описание (ru)',
            value: this.props.item.description_ru || '',
        },
        description_uk: {
            label: 'Описание (uk)',
            value: this.props.item.description_uk || '',
        },
        description_en: {
            label: 'Описание (en)',
            value: this.props.item.description_en || '',
        },
        price: {
            label: 'Цена',
            type: 'number',
            value: this.props.item.price || 0,
        },
        categoryId: {
            label: 'Категория',
            value: this.props.item.categoryId || null,
            items: [],
        },
        weight: {
            label: 'Выход (г.)',
            type: 'number',
            value: this.props.item.weight || 0,
        },
        images: {
            value: this.props.item.images || [],
        },
    });

    @action
    async fetchData() {
        this.formConfig.categoryId.items = await dishCategoryAPI.fetchCategoriesForSelect();
    }

    get Body() {
        return (
            <>
                <FormLangSwitch
                    ru={(
                        <>
                            <Input {...this.formConfig.title_ru} />
                            <Wysiwyg {...this.formConfig.description_ru} />
                        </>
                    )}
                    uk={(
                        <>
                            <Input {...this.formConfig.title_uk} />
                            <Wysiwyg {...this.formConfig.description_uk} />
                        </>
                    )}
                    en={(
                        <>
                            <Input {...this.formConfig.title_en} />
                            <Wysiwyg {...this.formConfig.description_en} />
                        </>
                    )}
                />
                <div>
                    <Select {...this.formConfig.categoryId} />
                    <Input {...this.formConfig.price} />
                    <Input {...this.formConfig.weight} />
                    <ImageArrayUploader {...this.formConfig.images} />
                </div>
            </>
        );
    }
}

export default DishFormModal;
