import React, { Component } from 'react';
import { Route, Redirect } from 'react-router';
import { inject, observer } from 'mobx-react';
import Layout from '../../layout/layout';

@inject('store')
@observer
class PrivateRoute extends Component {
    render() {
        if (!this.props.store.token) return <Redirect to="/" />;

        return (
            <Layout>
                <Route {...this.props} />
            </Layout>
        );
    }
}

export default PrivateRoute
