import api from './api';
import messenger from './messenger';
import AbstractAPI from './AbstractAPI';

export default new class extends AbstractAPI {
    route = '/user';

    async save(_data) {
        const data = { ..._data };
        if (!data.password) delete data.password;

        const res = data.id
            ? await api.axios.put(`/user/${data.id}`, data)
            : await api.axios.post(`/user`, data);

        messenger.success('Данные успешно сохранены');
        return res;
    }
}();
