import React from 'react';
import { observer } from 'mobx-react';
import ModalFormComponent from '../modal-form-component';
import { Input, Wysiwyg } from '../../component/form';
import FormLangSwitch from '../../component/form-lang-switch/form-lang-switch';
import ImageArrayUploader from '../../component/image-array-uploader/image-array-uploader';
import FileUploader from '../../component/form/file-uploader/file-uploader';
import pageAPI from '../../service/pageAPI';

@observer
class PageFormModal extends ModalFormComponent {

    api = pageAPI;

    modalNameClassName = 'page-form-modal';

    modalClassName = 'modal--w1000';

    modalBodyClassName = 'modal__body--2-cols';

    modalTitle = `Редактирование страницы: ${this.props.item.key}`;

    formConfig = this.prepareConfig({
        id: {
            value: this.props.item.id || null,
        },
        title_ru: {
            label: 'Заголовок (ru)',
            value: this.props.item.title_ru || '',
        },
        title_uk: {
            label: 'Заголовок (uk)',
            value: this.props.item.title_uk || '',
        },
        title_en: {
            label: 'Заголовок (en)',
            value: this.props.item.title_en || '',
        },
        subtitle_ru: {
            label: 'Подзаголовок (ru)',
            value: this.props.item.subtitle_ru || '',
            required: false,
        },
        subtitle_uk: {
            label: 'Подзаголовок (uk)',
            value: this.props.item.subtitle_uk || '',
            required: false,
        },
        subtitle_en: {
            label: 'Подзаголовок (en)',
            value: this.props.item.subtitle_en || '',
            required: false,
        },
        seo_title_ru: {
            label: 'SEO заголовок (ru)',
            value: this.props.item.seo_title_ru || '',
            required: false,
        },
        seo_title_uk: {
            label: 'SEO заголовок (uk)',
            value: this.props.item.seo_title_uk || '',
            required: false,
        },
        seo_title_en: {
            label: 'SEO заголовок (en)',
            value: this.props.item.seo_title_en || '',
            required: false,
        },
        seo_description_ru: {
            label: 'SEO описание (ru)',
            value: this.props.item.seo_description_ru || '',
            required: false,
        },
        seo_description_uk: {
            label: 'SEO описание (uk)',
            value: this.props.item.seo_description_uk || '',
            required: false,
        },
        seo_description_en: {
            label: 'SEO описание (en)',
            value: this.props.item.seo_description_en || '',
            required: false,
        },
        content_ru: {
            label: 'Контент (ru)',
            value: this.props.item.content_ru || '',
            required: false,
        },
        content_uk: {
            label: 'Контент (uk)',
            value: this.props.item.content_uk || '',
            required: false,
        },
        content_en: {
            label: 'Контент (en)',
            value: this.props.item.content_en || '',
            required: false,
        },
        mainImage: {
            label: 'Главное изображение',
            value: this.props.item.mainImage || null,
            required: false,
        },
        images: {
            label: 'Дополнительные изображения',
            value: this.props.item.images || [],
        },
    });

    get Body() {
        return (
            <>
                <FormLangSwitch
                    ru={(
                        <>
                            <Input {...this.formConfig.title_ru} />
                            <Input {...this.formConfig.subtitle_ru} />
                            <Input {...this.formConfig.seo_title_ru} />
                            <Input {...this.formConfig.seo_description_ru} />
                            <Wysiwyg {...this.formConfig.content_ru} />
                        </>
                    )}
                    uk={(
                        <>
                            <Input {...this.formConfig.title_uk} />
                            <Input {...this.formConfig.subtitle_uk} />
                            <Input {...this.formConfig.seo_title_uk} />
                            <Input {...this.formConfig.seo_description_uk} />
                            <Wysiwyg {...this.formConfig.content_uk} />
                        </>
                    )}
                    en={(
                        <>
                            <Input {...this.formConfig.title_en} />
                            <Input {...this.formConfig.subtitle_en} />
                            <Input {...this.formConfig.seo_title_en} />
                            <Input {...this.formConfig.seo_description_en} />
                            <Wysiwyg {...this.formConfig.content_en} />
                        </>
                    )}
                />
                <div>
                    <FileUploader {...this.formConfig.mainImage} />
                    <ImageArrayUploader {...this.formConfig.images} />
                </div>
            </>
        );
    }
}

export default PageFormModal;
