import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './filter-input.scss';

class FilterInput extends Component {

    static propTypes = {
        type: PropTypes.string,
        param: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string, PropTypes.number
        ]),
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        type: 'text',
        placeholder: '',
        value: '',
    };

    onChange = ({ target: { value } }) => {
        this.props.onChange(this.props.param, value);
    };

    render() {
        return (
            <div className="filter-input">
                <input
                    type={this.props.type}
                    value={this.props.value || ''}
                    placeholder={this.props.placeholder}
                    onChange={this.onChange}
                />
            </div>
        )
    }
}

export default FilterInput;
