import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './checkbox.scss';

const Checkbox = ({
    className,
    label,
    name,
    value,
    checked,
    readOnly,
    disabled,
    onChange,
}) => {
    const formFieldClassname = cn(
        'form-field',
        'checkbox',
        className,
    );

    return (
        <div className={formFieldClassname}>
            <label className="checkbox__box-label">
                <input
                    type="checkbox"
                    name={name}
                    value={value}
                    checked={checked}
                    readOnly={readOnly}
                    disabled={disabled}
                    onChange={({ target }) => onChange({
                        name,
                        value: target.value,
                        checked: target.checked
                    })}
                />
                <div className="checkbox__title">{label}</div>
            </label>
        </div>
    );
};

Checkbox.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    checked: PropTypes.bool,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

Checkbox.defaultProps = {
    className: '',
    label: '',
    name: null,
    value: null,
    checked: false,
    readOnly: false,
    disabled: false,
    onChange: () => {},
};

export default Checkbox;
