import React from 'react';
import { observer } from 'mobx-react';
import ModalFormComponent from '../modal-form-component';
import { Input, FileUploader } from '../../component/form';
import dishCategoryAPI from '../../service/dishCategoryAPI';

@observer
class DishCategoryFormModal extends ModalFormComponent {

    api = dishCategoryAPI;

    modalNameClassName = 'dish-category-form-modal';

    modalClassName = 'modal--w400';

    modalTitle = this.isCreate ? 'Создание категории' : `Редактирование категории: ${this.props.item.title_ru}`;

    formConfig = this.prepareConfig({
        id: {
            value: this.props.item.id || null,
        },
        title_ru: {
            label: 'Название (ru)',
            value: this.props.item.title_ru || '',
        },
        title_uk: {
            label: 'Название (uk)',
            value: this.props.item.title_uk || '',
        },
        title_en: {
            label: 'Название (en)',
            value: this.props.item.title_en || '',
        },
        image: {
            label: 'Изображение',
            value: this.props.item.image || null,
            required: false,
        }
    });

    get Body() {
        return (
            <>
                <Input {...this.formConfig.title_ru} />
                <Input {...this.formConfig.title_uk} />
                <Input {...this.formConfig.title_en} />
                <FileUploader {...this.formConfig.image} />
            </>
        );
    }
}

export default DishCategoryFormModal;
