import store from '../store'

class Permission {

    list = {
        USER_READ: 'user_read',
        USER_MANAGE: 'user_manage',
        ROLE_READ: 'role_read',
        ROLE_MANAGE: 'role_manage',
        ROOM_READ: 'room_read',
        ROOM_MANAGE: 'room_manage',
        FILE_MANAGE: 'file_manage',
        TRANSLATION_MANAGE: 'translation_manage',
        NEWS_MANAGE: 'news_manage',
        OFFERS_MANAGE: 'offers_manage',
        DISH_MANAGE: 'dish_manage',
        GALLERY_MANAGE: 'gallery_manage',
        SETTING_MANAGE: 'setting_manage',
        PAGE_MANAGE: 'page_manage',
        ORDER_READ: 'order_read',
        ORDER_MANAGE: 'order_manage',
    };

    can(permission) {
        return store.user?.role?.permissions.includes(permission);
    }
}

export default new Permission();
