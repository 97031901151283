import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobXPropTypes } from 'mobx-react';
import FileUploader from '../../component/form/file-uploader/file-uploader';
import './image-array-uploader.scss';

class ImageArrayUploader extends Component {

    static propTypes = {
        label: PropTypes.string,
        name: PropTypes.string.isRequired,
        value: MobXPropTypes.arrayOrObservableArrayOf(PropTypes.string).isRequired,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        label: 'Изображения',
    };

    onAdd = ({ value }) => {
        this.props.onChange({
            name: this.props.name,
            value: [...this.props.value, value]
        });
    };

    onRemove = ({ deletedValue }) => {
        this.props.onChange({
            name: this.props.name,
            value: this.props.value.filter(x => x !== deletedValue)
        });
    };

    render() {
        return (
            <div className="image-array-uploader">
                <label className="form-field__label">{this.props.label}</label>
                <div className="image-array-uploader__items">
                    {
                        this.props.value.map((x, i) => (
                            <FileUploader
                                key={x}
                                name={`${this.props.name}[${i}]`}
                                value={x}
                                onChange={this.onRemove}
                            />
                        ))
                    }
                    <FileUploader
                        name={`${this.props.name}[new]`}
                        value={null}
                        onChange={this.onAdd}
                    />
                </div>
            </div>
        );
    }
}

export default ImageArrayUploader;
