import React, { Component } from 'react';
import { Route, Redirect } from 'react-router';
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
class PublicRoute extends Component {
    render() {
        const { store, ...restProps } = this.props;
        if (store.token) return <Redirect to="/start" />;
        return <Route {...restProps} />;
    }
}

export default PublicRoute
