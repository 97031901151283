import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

class SortTh extends Component {

    static propTypes = {
        sortKey: PropTypes.string.isRequired,
        activeSortKey: PropTypes.string.isRequired,
        dir: PropTypes.oneOf(['asc', 'desc']).isRequired,
        title: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    get isActive() {
        return this.props.sortKey === this.props.activeSortKey;
    }

    get Icon() {
        if (!this.isActive) return null;
        const dir = this.props.dir === 'asc' ? 'down' : 'up';
        return <i className="material-icons">keyboard_arrow_{dir}</i>;
    }

    setSort = () => {
        const dir = (this.isActive && this.props.dir === 'asc') ? 'desc' : 'asc';
        this.props.onChange(this.props.sortKey, dir);
    };

    render() {
        return (
            <th
                className={cn('sortable', { [this.props.dir]: this.isActive })}
                onClick={this.setSort}
            >
                <div>
                    {this.props.title}
                    {this.Icon}
                </div>
            </th>
        );
    }
}

export default SortTh;
