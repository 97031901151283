import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './pagination.scss';

class Pagination extends Component {

    static propTypes = {
        page: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    LIMIT_PER_PAGE = 20;

    get page() {
        return this.props.page;
    }

    get pages() {
        return Math.ceil(this.props.total / this.LIMIT_PER_PAGE);
    }

    render() {
        if (this.pages < 2) return null;

        const p = Array(this.pages).fill(null).map((x, i) => i + 1);
        let pp;

        if (this.page < 10) pp = p.slice(0, 10);
        else if (this.page > this.pages - 10) pp = p.slice(-10);
        else pp = p.slice(this.page - 6, this.page + 4);

        return (
            <div className="pagination">
                {
                    this.page > 1 && (
                        <div
                            className="pagination__link pagination__link-prev"
                            onClick={() => this.setPage(this.page - 1)}
                        >&lt;</div>
                    )
                }
                {
                    (this.pages > 10 && this.page >= 10) && (
                        <div className="pagination__link pagination__link-points">...</div>
                    )}
                {
                    pp.map(n => (
                        <div className={cn({ 'pagination__link': true, 'active': n === this.page })}
                             key={n} onClick={() => this.setPage(n)}
                        >{n}</div>
                    ))
                }
                {
                    (this.pages > 10 && this.page < this.pages - 10) && (
                        <div className="pagination__link pagination__link-points">...</div>
                    )
                }
                {
                    this.page < this.pages && (
                        <div
                            className="pagination__link pagination__link-next"
                            onClick={() => this.setPage(this.page + 1)}
                        >&gt;</div>
                    )
                }
            </div>
        );
    }

    setPage = page => {
        if (page === this.page) return;
        this.props.onChange(page);
    };
}

export default Pagination;
