export const QP_TYPE = {
    integer: 'integer',
    string: 'string',
    integerArray: 'integerArray',
    stringArray: 'stringArray',
};


export const getQueryParamsFromUrl = (queryParamsConfig = [], url) => {
    const { searchParams } = new URL(url);
    const queryParams = {};

    queryParamsConfig.forEach(x => {
        if (!searchParams.get(x.key) && !searchParams.get(`${x.key}[]`)) return;
        let result;

        switch (x.type) {
            case QP_TYPE.stringArray:
                result = searchParams.getAll(`${x.key}[]`);
                break;

            case QP_TYPE.integerArray:
                result = searchParams.getAll(`${x.key}[]`).map(y => +y);
                break;

            case QP_TYPE.integer:
                result = +searchParams.get(x.key);
                break;

            case QP_TYPE.string:
            default:
                result = searchParams.get(x.key);
                break;
        }
        queryParams[x.key] = result;
    });

    return queryParams;
}

export const setQueryParamsToUrl = (queryParamsConfig = [], queryParams, stringUrl) => {
    const url = new URL(stringUrl);
    const { searchParams } = url;

    queryParamsConfig.forEach(x => {
        switch (x.type) {
            case QP_TYPE.stringArray:
            case QP_TYPE.integerArray:
                searchParams.delete(`${x.key}[]`);
                if (queryParams[x.key] && queryParams[x.key].length) {
                    queryParams[x.key].forEach(x => searchParams.append(`${x.key}[]`, x));
                }
                break;

            default:
                searchParams.delete(x.key);
                if (queryParams[x.key]) searchParams.set(x.key, queryParams[x.key]);
        }
    });

    return decodeURI(url.toString());
}

export const getQueryParamsFromCurrentUrl = queryParamsConfig =>
    getQueryParamsFromUrl(queryParamsConfig, window.location.href)

export const setQueryParamsToCurrentUrl = (queryParamsConfig, queryParams) => {
    const url = setQueryParamsToUrl(queryParamsConfig, queryParams, window.location.href);
    window.history.pushState(null, null, url);
};
