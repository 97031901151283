import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './input.scss';

const Input = ({
    className,
    label,
    type,
    name,
    value,
    error,
    readOnly,
    disabled,
    required,
    placeholder,
    maxLength,
    onChange,
    onBlur,
}) => {

    const formFieldClassname = cn(
        'form-field',
        'input',
        className,
        {
            'form-field--invalid': error
        }
    );

    return (
        <div className={formFieldClassname}>
            {label && <label className="form-field__label">{label} {required ? '*' : ''}</label>}
            <div className="form-field__input-box">
                <input
                    type={type}
                    name={name}
                    value={value}
                    readOnly={readOnly}
                    disabled={disabled}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    onChange={({ target }) => onChange({ name, value: target.value })}
                    onBlur={() => onBlur(name)}
                />
            </div>
            {error && <div className="form-field__error">{error}</div>}
        </div>
    );
};

Input.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.string,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    maxLength: PropTypes.number,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
};

Input.defaultProps = {
    className: '',
    label: '',
    type: 'text',
    value: '',
    error: null,
    readOnly: false,
    disabled: false,
    required: false,
    placeholder: '',
    maxLength: 256,
    onChange: () => {},
    onBlur: () => {},
};

export default Input;
