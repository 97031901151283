import React from 'react';
import { observer } from 'mobx-react';
import ModalFormComponent from '../modal-form-component';
import { Input } from '../../component/form';
import translationAPI from '../../service/translationAPI';

@observer
class TranslationFormModal extends ModalFormComponent {

    api = translationAPI;

    modalNameClassName = 'translation-form-modal';

    modalClassName = 'modal--w400';

    modalTitle = `Редактирование: ${this.props.item.key}`;

    formConfig = this.prepareConfig({
        id: {
            value: this.props.item.id,
        },
        key: {
            label: 'Key',
            value: this.props.item.key || '',
            disabled: true,
        },
        en: {
            label: 'EN',
            value: this.props.item.en || '',
        },
        ru: {
            label: 'RU',
            value: this.props.item.ru || '',
        },
        uk: {
            label: 'UK',
            value: this.props.item.uk || '',
        },
    });

    get Body() {
        return (
            <>
                <Input {...this.formConfig.key} />
                <Input {...this.formConfig.en} />
                <Input {...this.formConfig.ru} />
                <Input {...this.formConfig.uk} />
            </>
        );
    }
}

export default TranslationFormModal;
