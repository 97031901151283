import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import TablePage from '../../component/table/table-page';
import Loader from '../../component/loader/loader';
import Button from '../../component/button/button';
import NewsFormModal from '../../modal/news-form-modal/news-form-modal';
import { QP_TYPE } from '../../service/queryParams';
import permission from '../../service/permission';
import newsAPI from '../../service/newsAPI';

@observer
class NewsTablePage extends TablePage {

    queryParamsConfig = [
        { key: 'page', type: QP_TYPE.integer, default: 1 },
        { key: 'sort', type: QP_TYPE.string, default: 'id' },
        { key: 'dir', type: QP_TYPE.string, default: 'asc' },
        { key: 'filter.eq.id', type: QP_TYPE.integer, default: null },
        { key: 'filter.like.title_ru', type: QP_TYPE.string, default: null },
    ];

    columnsConfig = [
        {
            _key: 'id',
            title: 'ID',
            filter: {
                param: 'filter.eq.id',
                type: 'number',
            }
        },
        {
            _key: 'date',
            title: 'Дата',
        },
        {
            _key: 'title_ru',
            title: 'Название (ru)',
            filter: {
                param: 'filter.like.title_ru',
                type: 'text',
            }
        },
        {
            _key: 'actions',
            filter: {
                type: 'reset'
            }
        }
    ];

    api = newsAPI;

    render() {
        if (this.loading && this.itemsCount === null) return <Loader />;

        return (
            <div className="table-page">
                <div className="table-page__toolbar">
                    <Button
                        text="Создать"
                        styleType="primary"
                        onClick={() => this.setItemForModal({})}
                    />
                </div>
                <table>
                    {this.TableHead}
                    <tbody>
                        {this.FiltersRow}
                        {
                            this.items.map(x => (
                                <tr key={x.id}>
                                    <td>{x.id}</td>
                                    <td>{moment(x.date).format('DD.MM.YYYY')}</td>
                                    <td>{x.title_ru}</td>
                                    {
                                        permission.can(permission.list.NEWS_MANAGE) && (
                                            <td className="actions">
                                                <div className="button-box">
                                                    {this.EditButton(x)}
                                                    {this.DeleteButton(x)}
                                                </div>
                                            </td>
                                        )
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                {this.NoResult}
                {this.Pagination}
                {this.DeleteModal}
                {
                    this.itemForModal &&
                    <NewsFormModal
                        item={this.itemForModal}
                        onClose={() => this.setItemForModal(null)}
                        onSubmitSuccess={() => this.fetchData()}
                    />
                }
            </div>
        )
    }
}

export default NewsTablePage;
