import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import Button from '../component/button/button';
import permission from '../service/permission';
import api from '../service/api';
import './layout.scss';

@inject('store')
@withRouter
@observer
class Layout extends Component {

    @observable
    loading = false;

    navLinks = [
        {
            to: '/start',
            icon: 'house',
            title: 'Главная',
        },
        {
            to: '/orders',
            icon: 'monetization_on',
            title: 'Заказы',
            permission: permission.list.ORDER_READ
        },
        {
            to: '/rooms',
            icon: 'weekend',
            title: 'Номера',
            permission: permission.list.ROOM_READ
        },
        {
            to: '/offers',
            icon: 'local_offer',
            title: 'Спец. предложения',
        },
        {
            to: '/news',
            icon: 'campaign',
            title: 'Новости',
        },
        {
            to: '/dish-categories',
            icon: 'category',
            title: 'Категории блюд',
        },
        {
            to: '/dishes',
            icon: 'local_dining',
            title: 'Блюда',
        },
        {
            to: '/gallery',
            icon: 'collections',
            title: 'Галерея',
            permission: permission.list.GALLERY_MANAGE
        },
        {
            to: '/pages',
            icon: 'web',
            title: 'Страницы',
            permission: permission.list.PAGE_MANAGE
        },
        {
            to: '/users',
            icon: 'group',
            title: 'Пользователи',
            permission: permission.list.USER_READ
        },
        {
            to: '/roles',
            icon: 'perm_data_setting',
            title: 'Роли',
            permission: permission.list.ROLE_READ
        },
        {
            to: '/translations',
            icon: 'language',
            title: 'Переводы',
            permission: permission.list.TRANSLATION_MANAGE
        },
        {
            to: '/settings',
            icon: 'settings',
            title: 'Настройки',
        },
    ].filter(x => x.permission ? permission.can(x.permission) : true);

    get pageTitle() {
        return this.navLinks.find(x => x.to === this.props.location.pathname)?.title;
    }

    @action
    componentDidMount() {
        this.loading = true;
        api.fetchCoreData().then(action(() => this.loading = false));
    }

    render() {
        if (this.loading) return null;

        return (
            <div className="layout">
                <div className="layout__sidebar">
                    <div className="logo">
                        <img src="/ico-kiroff.svg" alt="" />
                        <hr />
                    </div>
                    <div className="layout__navigation">
                        <div className="layout__navigation-item">
                            {
                                this.navLinks.map(x => (
                                    <NavLink key={x.to} to={x.to}>
                                        <i className="material-icons">{x.icon}</i>
                                        <span>{x.title}</span>
                                    </NavLink>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="layout__main">
                    <header className="layout__header">
                        <div>{this.pageTitle}</div>
                        <div className="layout__header-user">
                            <div className="layout__header-user-name">
                                {this.props.store.user.name}
                            </div>
                            <Button
                                text="Выйти"
                                styleType="default"
                                sizeType="sm"
                                onClick={() => api.logout()}
                            />
                        </div>
                    </header>
                    <div className="layout__page-content">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default Layout;
