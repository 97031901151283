import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './filter-select.scss';

class FilterSelect extends Component {

    static propTypes = {
        param: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string, PropTypes.number
        ]),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                v: PropTypes.oneOfType([
                    PropTypes.string, PropTypes.number
                ]),
                t: PropTypes.oneOfType([
                    PropTypes.string, PropTypes.number
                ]),
            })
        ),
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        value: '',
        items: [],
    };

    onChange = ({ target: { value } }) => {
        this.props.onChange(this.props.param, value);
    };

    render() {
        return (
            <div className="filter-select">
                <select
                    value={this.props.value || ''}
                    onChange={this.onChange}
                >
                    <option value="">-</option>
                    {
                        this.props.items.map(x => (
                            <option key={x.v} value={x.v}>{x.t || x.v}</option>
                        ))
                    }
                </select>
            </div>
        )
    }
}

export default FilterSelect;
