import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { action, observable } from 'mobx';
import { observer, PropTypes as MobXPropTypes } from 'mobx-react';
import cn from 'classnames';
import './select.scss'

@observer
class Select extends Component {

    static propTypes = {
        items: MobXPropTypes.arrayOrObservableArrayOf(
            PropTypes.oneOfType([
                PropTypes.shape({
                    v: PropTypes.number,
                    t: PropTypes.string,
                }),
                PropTypes.shape({
                    id: PropTypes.number,
                    title: PropTypes.string,
                }),
            ])
        ),
        className: PropTypes.string,
        label: PropTypes.string,
        name: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string, PropTypes.number
        ]),
        error: PropTypes.string,
        readOnly: PropTypes.bool,
        disabled: PropTypes.bool,
        required: PropTypes.bool,
        placeholder: PropTypes.string,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
    };

    @observable
    isOpen = false;

    get items() {
        if (!this.props.items) return [];
        return this.props.items.map(x => ({
            v: x.v || x.id,
            t: x.t || x.title || x.v,
        }));
    }


    get openerText() {
        const selectedItem = this.items.find(x => x.v === this.props.value);
        if (!selectedItem) return '';
        return selectedItem.t;
    }

    get boxClasses() {
        return cn(
            'form-field',
            'select',
            this.props.className,
            {
                'form-field--invalid': this.props.error,
                'select--show': this.isOpen,
            }
        );
    }

    componentDidMount() {
        document.body.addEventListener('click', this.bodyClick);
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.bodyClick);
    }

    @action
    open() {
        this.isOpen = true;
    }

    @action
    close() {
        if (!this.isOpen) return;
        this.isOpen = false;
    }

    @action
    onOpenerClick = () => {
        if (this.props.disabled) return;
        this.isOpen ? this.close() : this.open();
    };

    @action
    bodyClick = e => {
        // if (!e.target.closest('.x-select.show'))
        this.close();
    };

    @action
    onItemClick(value) {
        if (this.props.disabled) return;
        this.props.onChange({ name: this.props.name, value });
        this.close();
    }

    render() {
        const { label, required } = this.props;
        const dir = this.isOpen ? 'up' : 'down';

        return (
            <div className={this.boxClasses} onClick={e => e.stopPropagation()}>
                {label && <label className="form-field__label">{label} {required ? '*' : ''}</label>}
                <div className="form-field__input-box">
                    <div className="select__opener" onClick={this.onOpenerClick}>
                        <span>{this.openerText}</span>
                        <i className="material-icons">keyboard_arrow_{dir}</i>
                    </div>
                    <div className="select__items">
                        {this.items.map(item => (
                            <div
                                key={item.v}
                                className="select__item"
                                onClick={() => this.onItemClick(item.v)}
                            >
                                {item.t}
                            </div>
                        ))}
                    </div>
                </div>
                {this.props.error && <div className="form-field__error">{this.props.error}</div>}
            </div>
        );
    }
}

export default Select;
