import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import ModalFormComponent from '../modal-form-component';
import { Input, Select } from '../../component/form';
import orderAPI from '../../service/orderAPI';
import './order-form-modal.scss';

@observer
class OrderFormModal extends ModalFormComponent {

    api = orderAPI;

    modalNameClassName = 'order-form-modal';

    modalClassName = 'modal--w800';

    modalBodyClassName = 'modal__body--2-cols';

    modalTitle = `Заказ №: ${this.props.item.id}`;

    formConfig = this.prepareConfig({
        id: {
            value: this.props.item.id || null,
        },
        status: {
            label: 'Статус',
            value: this.props.item.status || null,
            items: [
                'new',
                'in_progress',
                'completed',
                'canceled',
            ].map(v => ({ v })),
        },
        username: {
            label: 'Имя клиента',
            value: this.props.item.username || '',
        },
        phone: {
            label: 'Телефон',
            value: this.props.item.phone || '',
        },
        comment: {
            label: 'Комментарий',
            value: this.props.item.comment || '',
        },
        payment: {
            label: 'Способ облаты',
            value: this.props.item.payment || '',
        },
    });

    get Body() {
        return (
            <>
                <div>
                    <Select {...this.formConfig.status} />
                    <Input {...this.formConfig.username} />
                    <Input {...this.formConfig.phone} />
                    <Input {...this.formConfig.comment} />
                    <Input {...this.formConfig.payment} />
                </div>
                <div>
                    <label className="form-field__label">Заказ</label>
                    <table>
                        <tbody>
                            {
                                this.props.item.orderDishes.map(x => (
                                    <tr key={x.id}>
                                        <td>{x.dish.title}</td>
                                        <td>{x.amount} шт. x {x.price} грн.</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <div className="form-field__label">
                        Дата: {moment(this.props.item.createdAt).format('DD.MM.YYYY HH:mm')}
                    </div>
                    <div className="form-field__label">
                        Итого: {this.props.item.sum} грн.
                    </div>
                </div>
            </>
        );
    }
}

export default OrderFormModal;
