import api from './api';
import AbstractAPI from './AbstractAPI';

export default new class extends AbstractAPI {
    route = '/dish-category';

    async fetchCategoriesForSelect(params) {
        const { data } = await api.axios.get(`${this.route}/for-select`, { params });
        return data;
    }
}();
