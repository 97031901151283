import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import cn from 'classnames';
import './loader.scss';

@observer
class Loader extends Component {

    @observable
    show = false;

    componentDidMount() {
        setTimeout(action(() => this.show = true), 300);
    }

    render() {
        return (
            <div className={cn('loader', { 'show': this.show })}>
                <img src="/img/loader.svg" className="loader__img" alt="loader" />
            </div>
        );
    }
}

export default Loader
