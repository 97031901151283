import React from 'react';
import { observer } from 'mobx-react';
import ModalFormComponent from '../modal-form-component';
import { Input, Wysiwyg } from '../../component/form';
import FormLangSwitch from '../../component/form-lang-switch/form-lang-switch';
import newsAPI from '../../service/newsAPI';

@observer
class NewsFormModal extends ModalFormComponent {

    api = newsAPI;

    modalNameClassName = 'news-form-modal';

    modalClassName = 'modal--w800';

    modalTitle = this.isCreate ? 'Создание новоти' : `Редактирование новости: ${this.props.item.title_ru}`;

    formConfig = this.prepareConfig({
        id: {
            value: this.props.item.id || null,
        },
        date: {
            label: 'Дата',
            value: (this.props.item.date || '').replace(/T.+/, ''),
            type: 'date',
            required: false,
        },
        title_ru: {
            label: 'Название (ru)',
            value: this.props.item.title_ru || '',
        },
        title_uk: {
            label: 'Название (uk)',
            value: this.props.item.title_uk || '',
        },
        title_en: {
            label: 'Название (en)',
            value: this.props.item.title_en || '',
        },
        content_ru: {
            label: 'Контент (ru)',
            value: this.props.item.content_ru || '',
        },
        content_uk: {
            label: 'Контент (uk)',
            value: this.props.item.content_uk || '',
        },
        content_en: {
            label: 'Контент (en)',
            value: this.props.item.content_en || '',
        },
    });

    get Body() {
        return (
            <FormLangSwitch
                ru={(
                    <>
                        <Input {...this.formConfig.title_ru} />
                        <Input {...this.formConfig.date} />
                        <Wysiwyg {...this.formConfig.content_ru} />
                    </>
                )}
                uk={(
                    <>
                        <Input {...this.formConfig.title_uk} />
                        <Input {...this.formConfig.date} />
                        <Wysiwyg {...this.formConfig.content_uk} />
                    </>
                )}
                en={(
                    <>
                        <Input {...this.formConfig.title_en} />
                        <Input {...this.formConfig.date} />
                        <Wysiwyg {...this.formConfig.content_en} />
                    </>
                )}
            />
        );
    }
}

export default NewsFormModal;
